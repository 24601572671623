import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Criterion, ModalRef, SearchCriteria } from '@simpl/element-ng';
import { intersection } from 'lodash';
import { map, Observable, tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { ProductCatalogArticle } from '../../../../shared/api-client/services/lcas-api-client/models/catalog-product.model';
import { CreateDevice } from '../../../../shared/api-client/services/lcas-api-client/models/create-device.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { SearchDevicesCriteria } from '../models/search-device-criteria.enum';
import { ViewDataService } from '../../../shared/services/view-data.service';
import { AddProductsQuantityValidators } from './validators/add-products-quantity.validators';
import { DeviceActionService } from '../../../shared/services/device-action.service';

@Component({
  selector: 'app-add-devices-modal',
  templateUrl: './add-devices-modal.component.html',
  styleUrls: ['./add-devices-modal.component.scss'],
})
export class AddDevicesModalComponent implements OnChanges {
  private readonly integerPattern = '^-?[0-9]*$';

  readonly mediaBaseUrl = environment.mediaBaseUrl;

  readonly minNumberOfDevices = 0;

  @Input() location?: Location;

  @Input() modalRef?: ModalRef;

  form?: FormGroup;

  products$: Observable<ProductCatalogArticle[]> = this.viewDataService
    .getAddDevicesViewData()
    .pipe(
      tap((productCatalog) => {
        this.form = this.getFormGroup(productCatalog);
      }),
      map((products) => {
        this.products = products ?? [];

        const isFavoriteProducts = products
          .filter((product) => product.isFavorite === true)
          .sort((a, b) => a.title.localeCompare(b.title));

        const isNotFavoriteProducts = products
          .filter((product) => !product.isFavorite)
          .sort((a, b) => a.title.localeCompare(b.title));

        this.products = isFavoriteProducts.concat(isNotFavoriteProducts);
        this.filteredProducts = this.products;

        this.setSearchCriteria(this.products);

        return this.products;
      }),
    );

  filteredProducts: ProductCatalogArticle[] = [];

  products: ProductCatalogArticle[] = [];

  devicesAddedToFavorite: CreateDevice[] = [];

  searchCriteria: Criterion[] = [];

  private searchCriteriaDict = {
    searchCriteriaName: 'searchCriteriaName',
    searchCriteriaModel: 'searchCriteriaModel',
    searchCriteriaInstalled: 'searchCriteriaInstalled',
  };

  constructor(
    private viewDataService: ViewDataService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceActionService: DeviceActionService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  addProductToFavorites(product: ProductCatalogArticle): void {
    const updatedProduct: ProductCatalogArticle = product;
    this.form?.markAsTouched();

    if (!product.isFavorite) {
      updatedProduct.isFavorite = true;
    } else {
      updatedProduct.isFavorite = !updatedProduct.isFavorite;
    }

    const isProductFavorite = this.devicesAddedToFavorite.find(
      (productAddedToFavorite) =>
        productAddedToFavorite.productCatalogId === updatedProduct.model,
    );
    if (isProductFavorite) {
      // eslint-disable-next-line no-param-reassign
      this.devicesAddedToFavorite = this.devicesAddedToFavorite.filter(
        (item) => item.productCatalogId !== updatedProduct.model,
      );
    } else {
      this.addFavoriteProduct(updatedProduct);
    }
  }

  private addFavoriteProduct(filteredProduct: ProductCatalogArticle) {
    const device = {
      floorId: this.location?.floor.id,
      locationId: this.location?.id,
      productCatalogId: filteredProduct?.model,
      quantity: filteredProduct.quantity,
      isFavorite: filteredProduct?.isFavorite,
    } as CreateDevice;
    this.devicesAddedToFavorite.push(device);
  }

  saveDevices(products: ProductCatalogArticle[]): void {
    const createDevices = this.getDevicesToCreate(products).concat(
      this.devicesAddedToFavorite,
    );

    if (createDevices.length > 0 || this.devicesAddedToFavorite.length > 0) {
      this.deviceActionService.addDevices(createDevices);
      this.close();
    }
  }

  close(): void {
    this.modalRef?.hide();
  }

  filterProducts(searchCriteria: SearchCriteria): void {
    const criteriaDict = {
      searchCriteriaName: this.getCriteriaValueByName(
        searchCriteria,
        this.searchCriteriaDict.searchCriteriaName,
      ),
      searchCriteriaModel: this.getCriteriaValueByName(
        searchCriteria,
        this.searchCriteriaDict.searchCriteriaModel,
      ),
      searchCriteriaInstalled: this.getCriteriaValueByName(
        searchCriteria,
        this.searchCriteriaDict.searchCriteriaInstalled,
      ),
    };

    this.filteredProducts = intersection(
      this.products.filter(
        (x) =>
          !criteriaDict.searchCriteriaName ||
          this.isValueEqual(x.title, criteriaDict.searchCriteriaName),
      ),
      this.products.filter(
        (x) =>
          !criteriaDict.searchCriteriaModel ||
          this.isValueEqual(x.model, criteriaDict.searchCriteriaModel),
      ),
      this.products.filter(
        (x) =>
          !criteriaDict.searchCriteriaInstalled ||
          this.checkInstances(
            x.instances!,
            criteriaDict.searchCriteriaInstalled,
          ),
      ),
      this.products.filter(
        (x) =>
          !searchCriteria.value ||
          this.isValueContaining(x.title, searchCriteria.value) ||
          this.isValueContaining(x.model, searchCriteria.value),
      ),
    );

    this.changeDetectorRef.detectChanges();
  }

  private getFormGroup(sortedCatalogItems: ProductCatalogArticle[]): FormGroup {
    return this.formBuilder.group(
      sortedCatalogItems.reduce(
        (acc, product) => ({
          ...acc,
          ...this.mapProductToFormControl(product),
        }),
        {},
      ),
    );
  }

  private mapProductToFormControl(
    product: ProductCatalogArticle,
  ): Record<string, [number, Validators[]]> {
    return {
      [product.id]: [
        0,
        [
          Validators.required,
          Validators.pattern(this.integerPattern),
          Validators.min(this.minNumberOfDevices),
          AddProductsQuantityValidators.maxInstancesOfType(
            product,
            1,
            'SysXController',
          ),
          AddProductsQuantityValidators.minInstancesOfType(
            product,
            1,
            'SysXController',
          ),
        ],
      ],
    };
  }

  private getDevicesToCreate(
    products: ProductCatalogArticle[],
  ): CreateDevice[] {
    return Object.entries(this.form?.controls || {})
      .filter(([, control]) => control.value > 0 && control.dirty)
      .map(([productId, control]) => {
        const product = products.find((p) => p.id === productId);
        this.devicesAddedToFavorite = this.devicesAddedToFavorite.filter(
          (favoriteProduct) =>
            product?.model !== favoriteProduct.productCatalogId,
        );
        return {
          floorId: this.location?.floor.id,
          locationId: this.location?.id,
          productCatalogId: product?.model,
          quantity: control.value,
          isFavorite: product?.isFavorite,
        } as CreateDevice;
      });
  }

  private getCriteriaValueByName(
    searchCriteria: SearchCriteria,
    name: string,
  ): string | undefined {
    if (searchCriteria.criteria.some((x) => x.name === name)) {
      return searchCriteria.criteria.find((x) => x.name === name)
        ?.value as string;
    }

    return undefined;
  }

  private isValueContaining(value: string, searchValue: string): boolean {
    return value.toLowerCase().includes(searchValue.toLowerCase());
  }

  private isValueEqual(value: string, searchValue: string): boolean {
    return value.toLowerCase() === searchValue.toLowerCase();
  }

  private checkInstances(value: number, contains: string): boolean {
    return contains ===
      this.translateService.instant(SearchDevicesCriteria.INSTALLED)
      ? value > 0
      : value === 0;
  }

  private setSearchCriteria(products: ProductCatalogArticle[]): void {
    this.searchCriteria.push(
      {
        name: this.searchCriteriaDict.searchCriteriaName,
        label: 'BUILDING.ADD_DEVICES_MODAL.SEARCH_CRITERIA.NAME',
        options: [
          ...new Set(
            products
              .map((product) => product.title)
              .sort((a, b) => a.localeCompare(b)),
          ),
        ],
      },
      {
        name: this.searchCriteriaDict.searchCriteriaModel,
        label: 'BUILDING.ADD_DEVICES_MODAL.SEARCH_CRITERIA.MODEL',
        options: [
          ...new Set(
            products
              .map((product) => product.model)
              .sort((a, b) => a.localeCompare(b)),
          ),
        ],
      },
      {
        name: this.searchCriteriaDict.searchCriteriaInstalled,
        label: 'BUILDING.ADD_DEVICES_MODAL.SEARCH_CRITERIA.INSTALLED',
        options: [
          this.translateService.instant(SearchDevicesCriteria.INSTALLED),
          this.translateService.instant(SearchDevicesCriteria.NOT_INSTALLED),
        ],
      },
    );
  }
}
