import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { CommissionedEdgeService } from '../services/commissioned-edge.service';

export const commissionedEdgeCanActivateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  return inject(CommissionedEdgeService).checkActiveProjectStore(
    route.params.projectId,
    route,
  );
};
