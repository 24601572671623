import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ApiClientHelperService } from '../../../../../../shared/api-client/services/api-client-helper.service';
import { DeviceApiClientService } from '../../../../../../shared/api-client/services/device-api-client/device-api-client.service';
import {
  DelayTimer,
  RetriesNumber,
} from '../../../../../../shared/api-client/services/device-api-client/models/device.constants';
import { LcasApiClientService } from '../../../../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { Device } from '../../../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { PlannedDevice } from '../../../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { EdgeDeviceState } from '../../../../../../shared/devices-commissioning/models/edge-device-state.model';
import { EdgeDeviceActions } from '../../../../../../shared/devices-commissioning/store/edge-device.actions';
import { TimeZoneNotSetError } from '../errors/TimeZoneNotSet.error';

@Injectable({ providedIn: 'root' })
export class EdgeSetTimeZoneService {
  constructor(
    private deviceApiClientService: DeviceApiClientService,
    private lcasApiClientService: LcasApiClientService,
    private apiHelperService: ApiClientHelperService,
    private store: Store<{
      edgeDeviceState: EdgeDeviceState;
    }>,
  ) {}

  setEdgeDeviceTimezone(
    edgeDeviceId: string,
    edgeDevice: PlannedDevice,
  ): Observable<unknown> {
    return this.deviceApiClientService.getDeviceFeatures(edgeDeviceId).pipe(
      map((featuresData) => {
        const deviceInfoFeature = featuresData.data.find(
          (feature) => feature.type === 'DeviceInfo',
        );
        if (deviceInfoFeature) {
          return deviceInfoFeature;
        }
        throw new Error(
          '"device-info" must be present on an edge device in order to set the timezone',
        );
      }),
      switchMap((feature) =>
        this.deviceApiClientService.setEdgeTimezone(feature.id),
      ),
      mergeMap((commandData) => {
        if (commandData.data.attributes.status !== 'succeeded') {
          return this.pollForSetTimezoneCommandStatus(
            commandData.data.id,
            edgeDevice,
          );
        }
        return this.addTimezoneToEdgeDevice(edgeDevice);
      }),
      catchError((error) => {
        if (error instanceof TimeZoneNotSetError) {
          throw error;
        }
        return of(error);
      }),
    );
  }

  private pollForSetTimezoneCommandStatus(
    commandId: string,
    edgeDevice: PlannedDevice,
  ): Observable<unknown> {
    return this.deviceApiClientService.getCommand(commandId).pipe(
      mergeMap((commandData) => {
        if (commandData.data.attributes.status === 'succeeded') {
          return this.addTimezoneToEdgeDevice(edgeDevice);
        }

        throw new Error('Timezone not setup yet');
      }),
      retry({ count: RetriesNumber, delay: DelayTimer }),
      catchError(() => {
        throw new TimeZoneNotSetError();
      }),
    );
  }

  private addTimezoneToEdgeDevice(
    edgeDevice: PlannedDevice,
  ): Observable<Device> {
    return this.apiHelperService.callAndAddStore((store) => {
      return this.lcasApiClientService
        .patchDevice({
          id: edgeDevice.id,
          timeZone: store.building?.attributes.timeZone,
          eTag: edgeDevice.eTag,
        })
        .pipe(
          first(),
          tap((device) => {
            this.store.dispatch(
              EdgeDeviceActions.setEdgeDevice({
                edgeDevice: {
                  ...edgeDevice,
                  timeZone: device.timeZone,
                },
              }),
            );
          }),
        );
    });
  }
}
