import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { CommissionedEdgeService } from '../services/commissioned-edge.service';

export const commissionedEdgeCanActivateChildGuard: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  return inject(CommissionedEdgeService).checkActiveProjectStore(
    childRoute.params.projectId,
    childRoute,
  );
};
