import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { Point } from './models/point.model';
import { DataSource } from '../../../../sidemenu/dashboard/models/data-source.model';
import { DigestedPointValues } from './models/digested-point-values.model';
import { DigestedPointQueryFilter } from './models/digested-point-query-filter.model';

@Injectable({ providedIn: ApiClientModule })
export class PointApiClientService {
  START_DATE_TIME = '2023-01-01T00:00:00.00Z';

  constructor(
    private http: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  getPoint(pointId: string): Observable<Point> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.http.get<Point>(
        `${environment.pointApi}/organizations/${store.project?.id}/points/${pointId}`,
      ),
    );
  }

  getPreDigestedPointValues(
    pointId: string,
    dataSource: DataSource,
  ): Observable<DigestedPointValues> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.http.get<DigestedPointValues>(
        `${environment.pointApi}/organizations/${store.project?.id}/points/${pointId}/${dataSource.provider.valueType}/${dataSource.provider.rules[0]}?startDateTime=${this.START_DATE_TIME}`,
      ),
    );
  }

  getPreDigestedPointValuesWithParams(
    pointId: string,
    ruleID: string,
    filter: DigestedPointQueryFilter,
  ): Observable<DigestedPointValues> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.http.get<DigestedPointValues>(
        `${environment.pointApi}/organizations/${store.project?.id}/points/${pointId}/digests/${ruleID}`,
        { params: filter as unknown as HttpParams },
      ),
    );
  }

  getOnDemandPointValuesWithParams(
    pointId: string,
    strategyID: string,
    precision: string,
    filter: DigestedPointQueryFilter,
  ): Observable<DigestedPointValues> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.http.get<DigestedPointValues>(
        `${environment.pointApi}/organizations/${store.project?.id}/points/${pointId}/digest/${strategyID}/precision/${precision}`,
        { params: filter as unknown as HttpParams },
      ),
    );
  }

  setPoint(pointId: string, value: string): Observable<Point> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.http.patch<Point>(
        `${environment.pointApi}/organizations/${store.project?.id}/points/${pointId}`,
        [
          {
            op: 'replace',
            path: '/value',
            value,
          },
        ],
      ),
    );
  }
}
