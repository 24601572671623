import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiToastNotificationService } from '@simpl/element-ng';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { EdgeSetTimeZoneService } from '../../../v2/sidemenu/commissioning/device-installation/edge-device-installation/services/edge-set-timezone.service';
import { ActiveProjectState } from '../../active-project/models/active-project-state.model';
import { LcasApiClientService } from '../../api-client/services/lcas-api-client/lcas-api-client.service';
import { PlannedDevice } from '../../api-client/services/lcas-api-client/models/planned-device.model';
import { EdgeDeviceState } from '../models/edge-device-state.model';
import { EdgeDeviceActions } from '../store/edge-device.actions';

type AllowedUrlSegment = 'floor-view' | 'edge-installation';

@Injectable({ providedIn: 'root' })
export class CommissionedEdgeService {
  constructor(
    private lcasApiClient: LcasApiClientService,
    private siToastNotificationService: SiToastNotificationService,
    private store: Store<{
      edgeDeviceState: EdgeDeviceState;
      project: ActiveProjectState;
    }>,
    private router: Router,
    private edgeSetTimeZoneService: EdgeSetTimeZoneService,
  ) {}

  showToast(messageContent: string): void {
    this.siToastNotificationService.queueToastNotification(
      'warning',
      'GUARDS.TOAST.TITLE.ACCESS_WARNING',
      messageContent,
    );
  }

  checkActiveProjectStore(
    projectId: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store
      .select((store) => store.project.activeProject)
      .pipe(
        first(),
        switchMap((activeProject) => {
          if (activeProject?.id) {
            return this.checkEdgeDeviceStore(projectId, route);
          }
          return of(true);
        }),
      );
  }

  checkEdgeDeviceStore(
    projectId: string,
    route?: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store
      .select((store) => store.edgeDeviceState)
      .pipe(
        first(),
        switchMap((edgeDeviceState) => {
          if (edgeDeviceState.isEdgeDeviceLoaded) {
            return of(
              this.checkEdgeDevice(
                edgeDeviceState.edgeDevice,
                projectId,
                route,
              ),
            );
          }

          return this.getEdgeDevice(projectId, route);
        }),
      );
  }

  private checkEdgeDevice(
    edgeDevice: PlannedDevice | undefined,
    projectId: string,
    route?: ActivatedRouteSnapshot,
  ): boolean | UrlTree {
    if (edgeDevice?.setupStatus === 'COMMISSIONED') {
      this.checkEdgeDeviceTimezone(edgeDevice);
    }

    if (
      route?.routeConfig?.path !== 'edge-installation' &&
      !this.isEdgeCommissioned(edgeDevice)
    ) {
      if (route?.routeConfig?.path !== 'scan-and-assign') {
        this.showToast('GUARDS.TOAST.MESSAGE.COMMISSION_EDGE');
      }
      return this.buildUrlTree('edge-installation', projectId);
    }

    return true;
  }

  private checkEdgeDeviceTimezone(edgeDevice: PlannedDevice): void {
    this.store
      .select((store) => store.project.activeBuilding)
      .pipe(
        first(),
        switchMap((activeBuilding) => {
          const buildingTimeZone =
            activeBuilding?.attributes.timeZone?.toLowerCase();
          const edgeDeviceTimeZone = edgeDevice.timeZone?.toLowerCase();
          if (buildingTimeZone && buildingTimeZone !== edgeDeviceTimeZone) {
            return this.addTimezoneToEdgeDevice(edgeDevice);
          }
          return of();
        }),
      )
      .subscribe();
  }

  private getEdgeDevice(
    projectId: string,
    route?: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.lcasApiClient.getDevices({ type: ['SysXController'] }).pipe(
      tap(
        (devices) =>
          !devices.length &&
          this.showToast('GUARDS.TOAST.MESSAGE.ADD_EDGE_TO_ROOM'),
      ),
      switchMap((devices) =>
        devices.length > 0
          ? of(devices).pipe(
              tap((edgeDevices) =>
                this.store.dispatch(
                  EdgeDeviceActions.setEdgeDevice({
                    edgeDevice: edgeDevices[0],
                  }),
                ),
              ),
              map((edgeDevices) =>
                this.checkEdgeDevice(edgeDevices[0], projectId, route),
              ),
              catchError(() =>
                of(this.buildUrlTree('edge-installation', projectId)),
              ),
            )
          : of(this.router.createUrlTree(['/sidemenu', projectId, 'building'])),
      ),
    );
  }

  private buildUrlTree(
    navigateTo: AllowedUrlSegment,
    projectId: string,
  ): UrlTree {
    return this.router.createUrlTree([
      '/sidemenu',
      projectId,
      'commissioning',
      'connect',
      navigateTo,
    ]);
  }

  private isEdgeCommissioned(edgeDevice: PlannedDevice | undefined) {
    return edgeDevice?.setupStatus === 'COMMISSIONED';
  }

  private addTimezoneToEdgeDevice(
    edgeDevice: PlannedDevice,
  ): Observable<unknown> {
    if (edgeDevice.instance?.edgeConfig?.deviceId) {
      return this.edgeSetTimeZoneService.setEdgeDeviceTimezone(
        edgeDevice.instance?.edgeConfig?.deviceId,
        edgeDevice,
      );
    }
    return of();
  }
}
